<template>
  <div class="login-container">
    <el-container>
      <el-header class="login-header" :height="headerHeight"></el-header>
      <el-main class="login-main">
        <el-form ref="loginForm" :model="loginForm" class="login-form" auto-complete="on" label-position="left">
          <div class="title-container">
            <img src="@/assets/logo/logo.png" class="logo" />
            <span class="title">时间统计</span>
          </div>
          <el-form-item prop="username">
            <el-input
              ref="username"
              v-model="loginForm.username"
              placeholder="请输入用户名"
              name="username"
              type="text"
              tabindex="1"
              auto-complete="on"
            >
              <svg-icon slot="prepend" icon-class="user" />
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              tabindex="2"
              auto-complete="on"
              @keyup.enter.native="handleLogin"
            >
              <svg-icon slot="prepend" icon-class="password" />
              <svg-icon slot="append" :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" @click="showPwd" />
            </el-input>
          </el-form-item>

          <el-button :loading="loading" type="primary" class="login-btn" @click.native.prevent="handleLogin">登录</el-button>
        </el-form>
      </el-main>
      <el-footer class="login-footer" :height="headerHeight">
        <div><a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2020021395号</a></div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { getTestJson } from '@/api/test'
import Variables from '@/styles/variables.scss'
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    }
  },
  computed: {
    headerHeight() {
      return Variables.headerHeight
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    if (process.env.NODE_ENV === 'development') {
      this.setDevPassword()
    }
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.loading = true
      this.loginRequest()
    },
    loginRequest() {
      this.$store.dispatch('user/login', this.loginForm)
        .then(() => {
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery }, () => {})
          this.loading = false
        })
        .catch(option => {
          this.$message.error(option.msg)
          this.loading = false
        })
    },
    setDevPassword() {
      this.$set(this.loginForm, 'username', process.env.VUE_APP_USERNAME)
      this.$set(this.loginForm, 'password', process.env.VUE_APP_PASSWORD)
    },
    onClickTestApi() {
      getTestJson().then(res => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
$bg:#FFFFFF;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
    min-height: 100%;
    width: 100%;
    background: $bg;
    background-size: contain;
    overflow: hidden;

    .login-form {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -200px;
        padding: 40px 55px 25px;
        background: #FFFFFF;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.19);
        border-radius: 4px;
    }
    .login-btn{
        width: 100%;
        margin: 10px 0 20px;
    }
    .el-input{
        >>>.el-input-group__append, >>>.el-input-group__prepend{
            background: #FFFFFF;
        }
    }

    .tips {
        font-size: 14px;
        color: #323232;
        margin-bottom: 10px;
        span {
            &:first-of-type {
                margin-right: 16px;
            }
        }
    }

    .title-container {
        position: relative;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 2px solid #00C185;
        .logo{
            height: 40px;
            width: 40px;
            margin-right: 15px;
            vertical-align: text-bottom;
        }
        .title {
            display: inline-block;
            vertical-align: text-bottom;
            font-size: 24px;
            line-height: 40px;
            margin: 0px auto;
        }
    }

    .login-header {
        transition: background .2s;
        background: #fff;
        backdrop-filter: none;
        box-shadow: 0 4px 15px rgba(0,0,0,.08);
    }

    .login-main {
        height: calc(100vh - #{$headerHeight} - #{$headerHeight});
    }
    .login-footer {
        background: #252525;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
